<template>
  <kurcc-form-multi-choice-additional-buttons-dialog-template
    icon="mdi-comment-outline"
    title="Write Comment" @cancel="cancel" @save="save">
    <v-textarea slot="body" v-model="localAnswer" outlined/>
  </kurcc-form-multi-choice-additional-buttons-dialog-template>
</template>

<script>
import { updateQuestionOptionField } from '@/modules/inspection/helpers/helpers'

export default {
  name: 'KurccFormMultiChoiceAdditionalButtonsTextComment',
  props: {
    answer: {
      required: true
    },
    indexes: {
      type: Object,
      required: true
    }
  },
  components: {
    KurccFormMultiChoiceAdditionalButtonsDialogTemplate:
      () => import('@/modules/inspection/components/custom-fields/KurccFormMultiChoiceAdditionalButtons/KurccFormMultiChoiceAdditionalButtonsDialogTemplate')
  },
  data () {
    return {
      initialAnswer: undefined,
      localAnswer: this.$lodash.cloneDeep(this.answer),
      updateQuestionOptionField
    }
  },
  watch: {
    answer: {
      handler: function (v) {
        this.initialAnswer = v
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    cancel () {
      this.updateQuestionOptionField('textCommentAnswer', this.initialAnswer, this.indexes)
    },
    save () {
      this.updateQuestionOptionField('textCommentAnswer', this.localAnswer, this.indexes)
    }
  }
}
</script>
